import { AvailableLabTestsResponse } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import CollectionTitle from "components/StyledComponents/CollectionTitle";
import Translate from "components/Translate/Translate";
import React, { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  AppointmentAvatar,
  IconFile,
  IconLocation,
  InfoCircleIcon
} from "src/constants/icons";
import {
  APP_BREAKPOINT,
  APP_CONTENT_WIDTH_APPOINTMENT
} from "src/constants/layout";
import { OpenBrowser } from "src/hybrid/components/Browser";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import AppointmentsBloc, {
  AppointmentSanityDetails
} from "src/state/AppointmentsBloc/AppointmentsBloc";
import FilesCubit from "src/state/FilesCubit/FilesCubit";
import { toast, tracker } from "src/state/state";
import { AppQueryPopupsController } from "src/ui/components/AppQueryPopups/AppQueryPopupsBloc";
import InDialog from "src/ui/components/InDialog/InDialog";
import SanityBlockContent from "src/ui/components/SanityBlockContent/SanityBlockContent";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";

const LabContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const EventContent = styled.div`
  ol {
    padding-left: 1.5rem;
  }

  a {
    color: var(--color-sunrise-blue);
  }
`;

const GradientWrap = styled.div`
  position: relative;
  width: 100%;
  height: 8.5rem;
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${APP_BREAKPOINT}px) {
    height: 6.5rem;
  }
`;

const GradientForm = styled.div`
  position: absolute;
  width: 160%;
  height: 70vh;
  bottom: 0;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  background: linear-gradient(135.11deg, #425be1 50%, #1b2183 100%);

  &[data-provider="KWIK_TRIP_CENTER_FOR_HEALTH"] {
    background: linear-gradient(135.11deg, #d32131 50%, #c0202e 100%);
  }

  &[data-provider="QUEST"] {
    background: linear-gradient(135.11deg, #1f4b24 18.01%, #467635 31.96%);
  }

  @media screen and (max-width: ${APP_BREAKPOINT}px) {
    width: 260%;
  }
`;

const DefaultAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -1.875rem;
  height: 6.5rem;
  width: 6.5rem;
  border-radius: 50%;
  background-color: var(--color-cream);
  object-fit: contain;
  border: 0.25rem solid var(--color-gray-light);
`;

const Picture = styled.img`
  object-fit: cover;
  position: absolute;
  bottom: -1.875rem;
  height: 6.5rem;
  width: 6.5rem;
  border-radius: 50%;
  background-color: var(--color-cream);
  border: 0.25rem solid var(--color-gray-light);
  padding: 1rem;
`;

const Wrap = styled.div`
  max-width: ${APP_CONTENT_WIDTH_APPOINTMENT / 16}rem;
  margin-top: 3rem;

  @media screen and (max-width: ${APP_BREAKPOINT}px) {
    padding: 0 1.5rem;
  }
`;

const ActionsWrap = styled.div`
  margin-top: 1.5rem;
  border-top: 0.125rem solid var(--color-cream-dark);
  border-bottom: 0.125rem solid var(--color-cream-dark);
  padding: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const ActionContainer = styled.div`
  background-color: var(--color-white);
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: var(--light-shadow);

  &:hover {
    box-shadow: var(--large-shadow);
  }

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

const Action = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
`;

export type SupportedLabProvider =
  | AvailableLabTestsResponse.labOrderProvider.KWIK_TRIP_CENTER_FOR_HEALTH
  | AvailableLabTestsResponse.labOrderProvider.LABCORP
  | AvailableLabTestsResponse.labOrderProvider.QUEST;

const LabInstructionsDialog: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();

  const onClose = () => {
    AppQueryPopupsController.closePopup();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  const [labDetails, setLabDetails] =
    React.useState<AppointmentSanityDetails>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const fileId = searchParams.get("fileId") ?? "";
  const location = searchParams.get("location") ?? "";
  const provider = (searchParams.get("provider") ?? "") as SupportedLabProvider;

  const ProviderLocationUrl: Record<SupportedLabProvider, string> = {
    [AvailableLabTestsResponse.labOrderProvider.QUEST]:
      "https://www.questdiagnostics.com/locations",
    [AvailableLabTestsResponse.labOrderProvider.LABCORP]:
      "https://www.labcorp.com/labs-and-appointments",
    [AvailableLabTestsResponse.labOrderProvider.KWIK_TRIP_CENTER_FOR_HEALTH]:
      "https://biometrics.kwiktrip.com"
  };
  const findLabUrl = ProviderLocationUrl[provider];
  const learnMoreUrl = "https://join9am.com/blog/which-lab-tests-do-i-need";

  useEffect(() => {
    const fetchCmsDetails = async () => {
      setLoading(true);
      try {
        const data = await AppointmentsBloc.getAppointmentCmsDetails(
          provider as SupportedLabProvider
        );
        setLabDetails(data);
      } catch (error) {
        toast.error("error_lab_details_load_failed");

        reportErrorSentry(error);
      } finally {
        setLoading(false);
      }
    };

    void fetchCmsDetails();
  }, []);

  if (loading || !labDetails) {
    return null;
  }

  const handleDownloadReqFile = () => {
    if (fileId === "") {
      reportErrorSentry(new Error("No file id found"));
      toast.error("download.failed");
      return;
    }

    tracker.track("Download requisition file");

    void FilesCubit.startFileDownload(fileId);
  };

  const handleFindLab = () => {
    tracker.track("Find a Lab button clicked", {
      data: {
        provider: provider
      }
    });

    void OpenBrowser(findLabUrl, {
      useBaseUrl: false
    });
  };

  const handleLearnMore = () => {
    tracker.track("Learn More button clicked", {});

    void OpenBrowser(learnMoreUrl, {
      useBaseUrl: false
    });
  };

  const fullTitle = `${labDetails.eventName}${location ? ` - ${location}` : ""}`;

  return (
    <InDialog
      width="min(calc(100% - 4rem), 120rem)"
      height="min(calc(100% - 4rem), 70rem)"
      simple
      backdropDismiss={false}
      title={translate("appointment.popup.title")}
      onClose={onClose}
      returnUrl={returnUrl}
    >
      <LabContent>
        <GradientWrap>
          <GradientForm data-provider={provider} />
          {labDetails.avatar ? (
            <Picture src={labDetails.avatar.url} alt={labDetails.eventName} />
          ) : (
            <DefaultAvatar>
              <AppointmentAvatar />
            </DefaultAvatar>
          )}
        </GradientWrap>

        <Wrap>
          <h1
            className="as-h4"
            style={{
              textAlign: "center"
            }}
          >
            {fullTitle}
          </h1>

          {/* <h2
            className="as-body2 color-c-80"
            style={{ marginTop: "24px", textAlign: "center" }} //TODO time 
          >
          Before ...
          </h2> */}

          <ActionsWrap>
            <Action onClick={handleFindLab}>
              <ActionContainer>
                <IconLocation />
              </ActionContainer>
              <Translate msg="lab.action.label.findALab" />
            </Action>

            <Action onClick={handleDownloadReqFile}>
              <ActionContainer>
                <IconFile />
              </ActionContainer>
              <Translate msg="lab.action.label.showRequisition" />
            </Action>

            <Action onClick={handleLearnMore}>
              <ActionContainer>
                <InfoCircleIcon />
              </ActionContainer>
              <Translate msg="button.learnMore" />
            </Action>
          </ActionsWrap>

          {labDetails.eventContent.map((content) => {
            return (
              <>
                <CollectionTitle style={{ marginTop: "24px" }}>
                  {content.contentTitle}
                </CollectionTitle>

                <EventContent>
                  <SanityBlockContent blocks={content.content} />
                </EventContent>
              </>
            );
          })}
        </Wrap>
      </LabContent>
    </InDialog>
  );
};

export default LabInstructionsDialog;
